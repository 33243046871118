<template>
	<div class="container-fluid h-200 my-2">
		<div class="row justify-content-center align-items-center h-50">
			<div class="col-auto">
				<div class="box" :class="{ 'plan': isAndroidRendering, 'plan-regular': isRegularRendering }">
					<h2>{{ $t('plan.limite_chevaux_atteinte') }}</h2>
					<template v-if="pending">
						<div class="alert alert-info text-center">
							<p>{{ $t('plan.paiement_refresh') }}.</p>
							<p @click="checkTreatementStatus"><u>{{ $t('plan.paiement_refresh_now') }} <font-awesome-icon :icon="['fad', 'sync']" :spin="working" /></u></p>
						</div>
					</template>

					<template v-if="!processingPurchase && !error">
						<template v-if="isMVP">
							<template v-if="isFirstPage">
								<p>{{ $t('plan.besoin_plus_chevaux') }}</p>
								<div class="border rounded p-2 text-center mt-4 mb-1">
									<h3>{{ $t('plan.passez_50_chevaux') }}</h3>
									<div class="price_plan">
									<b>€{{ prices.per_year_monthly }}</b> <sup>{{ $t('plan.forfait_par_mois') }}</sup>
									</div>
								</div>
								
								<b-button variant="primary" class="btn-block mt-4" @click="buyPlan">{{ $t('plan.plus_options') }}</b-button>
							</template>
							<template v-else-if="isOptionsPage">
								<b-card-group deck>
									<b-card
										:title="$t('plan.annuel')"
										@click="switchPlan('annual')"
										:class="{ 'plan-box': isAnnuelSelected}"
									>
										<b-card-text>
											<!-- Price -->
											<div class="price_plan text-center"><b>{{ prices.per_year }} {{ $t('plan.sigle') }}</b></div>
											
											<!-- Explanation -->
											<small>{{ $t('plan.soit') }} {{ prices.per_year_monthly }}{{ $t('plan.sigle') }}<sup>{{ $t('plan.forfait_par_mois') }}</sup></small>
										</b-card-text>
									</b-card>
									<b-card
										:title="$t('plan.mensuel')"
										@click="switchPlan('monthly')"
										:class="{ 'plan-box': isMensuelSelected}"
									>
										<b-card-text>
											<div class="price_plan text-center"><b>{{ prices.per_month }} {{ $t('plan.sigle') }}</b></div>
											
											<small>{{ $t('plan.soit') }} {{ prices.per_month }}{{ $t('plan.sigle') }}<sup>{{ $t('plan.forfait_par_mois') }}</sup></small>
										</b-card-text>
									</b-card>
								</b-card-group>

								<b-button v-if="isAnnuelSelected" class="btn-block mb-2" variant="primary" @click="buyPlan">{{ $t('plan.profitez_offre_annuelle', [prices.per_year]) }}</b-button>
								<b-button  v-else-if="isMensuelSelected" class="btn-block mb-2" variant="primary" @click="buyPlan">{{ $t('plan.profitez_offre_mensuelle', [prices.per_month]) }}</b-button>
				
								<!-- <small class="d-block text-center">{{ $t('plan.details_facturation_annuelle_bis') }}</small> -->
							</template>
						</template>
						<template v-else>
							<template v-if="isFirstPage">
								<h4>{{ $t('plan.maximum_horses_not_mvp') }}</h4>
							</template>
							<template v-else-if="isOptionsPage">
								<b-card-group deck>
									<b-card
										:title="$t('plan.annuel')"
										@click="switchPlan('annual')"
										:class="{ 'plan-box': isAnnuelSelected}"
									>
										<b-card-text>
											<!-- Price -->
											<div class="price_plan text-center"><b>{{ prices.per_year }} {{ $t('plan.sigle') }}</b></div>
											
											<!-- Explanation -->
											<small>{{ $t('plan.soit') }} {{ prices.per_year_monthly }}{{ $t('plan.sigle') }}<sup>{{ $t('plan.forfait_par_mois') }}</sup></small>
										</b-card-text>
									</b-card>
									<b-card
										:title="$t('plan.mensuel')"
										@click="switchPlan('monthly')"
										:class="{ 'plan-box': isMensuelSelected}"
									>
										<b-card-text>
											<div class="price_plan text-center"><b>{{ prices.per_month }} {{ $t('plan.sigle') }}</b></div>
											
											<small>{{ $t('plan.soit') }} {{ prices.per_month }}{{ $t('plan.sigle') }}<sup>{{ $t('plan.forfait_par_mois') }}</sup></small>
										</b-card-text>
									</b-card>
								</b-card-group>

								<b-button v-if="isAnnuelSelected" class="btn-block mb-2" variant="primary" @click="buyPlan">{{ $t('plan.profitez_offre_annuelle', [prices.per_year]) }}</b-button>
								<b-button  v-else-if="isMensuelSelected" class="btn-block mb-2" variant="primary" @click="buyPlan">{{ $t('plan.profitez_offre_mensuelle', [prices.per_month]) }}</b-button>
								
								<!-- <small class="d-block text-center">{{ $t('plan.details_facturation_annuelle_bis') }}</small> -->
							</template>


						</template>

					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Constants from '../../../config.local.js'
	import _debounce from 'lodash/debounce';
	import _floor from 'lodash/floor'
	import IAP from "@/mixins/ios/IAP.js"
	import Horse from "@/mixins/Horse.js"
	import Navigation from "@/mixins/Navigation.js"

	export default {
		name: "IosBuyPnal",
		mixins: [IAP, Horse, Navigation],
		data() {
			return {
				plans: [],
				// Regular
				prices: {
					'per_month': '6,60',
					'per_year' : 72,
					'per_year_monthly': '6,60' // le prix de revient par mois, sur un forfait annuel
				},
				working: false,   // demande de paiement
				pending: false,   // traitement de la transaction
				processed: false, // upgrade licence OK
				error: false,
				attempts: 0, // essaie actuel
				max_check_attempts: 24, // nombre d'essais d'obtention des droits d'ajout de cheval - 2 minutes
				state: 'main', // main / options
				//selected_plan: 'annual', // planId,
				selected_plan: 'monthly',
				isMVP: true,

				default_link: "https://www.groomy-app.com/fr/tarifs/particuliers.html"
			}
		},
		created() {

		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				const gamme = await this.getCurrentUserGamme()
				this.isMVP = gamme.gamme[0].gamme_id == 1
			},
			goToMoreOptions() {
				this.state = 'options'
			},
			switchPlan(tag) {
				this.selected_plan = tag
			},
			buyPlan() {
				window.open(this.default_link, '_blank')
				this.pending = true
			},
			checkTreatementStatus() {
				if(this.attempts >= this.max_check_attempts) {
					this.error = true
					return
				}

				this.working = true
				this.attempts += 1

				this.checkCanAdd().then(res => {
					// Si on a les droits, c'est que la transaction a bien été traitée côté laravel
					if(res === true) {
						this.processed = true
						this.$emit('purchased')
					}
				})
				.catch(e => {
					console.error("BuyPlan::checkTreatementStatus =>", e)
				})
				.finally(() => {
					this.working = false
				})
			}
		},
		computed: {
			isAndroidRendering() {
				return !!Constants.IS_AND_RUNNING
			},
			isRegularRendering() {
				return !this.isAndroidRendering
			},
			isFirstPage() {
				return this.state == 'main'
			},
			isOptionsPage() {
				return this.state == 'options'
			},
			isAnnuelSelected() {
				return this.selected_plan.indexOf('annual') > -1
			},
			isMensuelSelected() {
				return this.selected_plan.indexOf('monthly') > -1
			},
			processingPurchase() {
				return this.working || this.pending || this.processed
			}
		}
	}
</script>