import Constants from '../../../config.local.js'

export default {
    methods: {
		async submitTransaction(transaction_identifier) {
			const url = Constants.USER_REGISTER_IAP_TRANSACTION + "?licence_key=" + Constants.USER_LICENCE_KEY
			return this.$request.request_post_api("ModelMixin::updateModelTemplate", url, { identifier: transaction_identifier }, false)
			.catch(e => {
				console.error("IPAMixin::submitTransaction => An error occured:", e)
				return null
			})
		}
	}
}